import React, { useState, useEffect } from "react";
import "./style.css";

const About = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="about-us">
      {loading && (
        <div className="loading-bar">
          <div className="progress"></div>
        </div>
      )}

      {!loading && (
        <>
          <h2>About Us</h2>
          <p>
            Welcome to <strong>OneTap Anime</strong>, your ultimate destination for watching anime online! We are passionate about bringing anime enthusiasts together by offering a vast collection of
            <strong> free dubbed and subbed anime</strong> at your fingertips. Whether you're a seasoned otaku or a newcomer exploring the world of anime, we’ve got something for everyone.
          </p>
          <p>
            At <strong>OneTap Anime</strong>, we pride ourselves on providing:-
            <br /> ➤ <strong>A Diverse Anime Library</strong>:- From the latest releases to fan-favorite classics, explore a wide range of genres, including action, romance, fantasy, and more.
            <br /> ➤ <strong>High-Quality Streaming</strong>:- Enjoy seamless HD anime streaming without interruptions or subscriptions.
            <br /> ➤ <strong>User-Friendly Experience</strong>:- With an intuitive interface and easy navigation, finding and streaming your favorite shows has never been simpler.
          </p>
          <p>We’re committed to offering a platform that caters to all anime lovers. Our mission is to create a space where you can dive into the world of anime anytime, anywhere, without restrictions or hidden costs.</p>
          <p>
            Thank you for choosing <strong>OneTap Anime</strong>. We’re constantly updating our collection and improving our platform to ensure the best experience for our users. Stay tuned for the latest anime episodes, trending series, and so much more. Let’s keep the anime love alive, one tap at a time!
          </p>
        </>
      )}
    </div>
  );
};

export default About;
